import { memo } from 'react';
import PropTypes from 'prop-types';

const CustomSwitchOn = (props) => {
    const { switchId, onText, isSwitchedOn, isVisible, isDesktopView, fullWidth } = props;

    return (
        <span id={`${switchId}-on-node`} className="CustomSwitchOn__text">
            {onText}

            <style jsx>
                {`
                    .CustomSwitchOn {
                        &__text {
                            width: ${fullWidth ? '100%' : 'auto'};
                            height: 100%;
                            display: flex;
                            align-items: center;
                            white-space: normal;
                            font-size: ${isDesktopView ? '14px' : '12px'};
                            font-weight: 600;
                            line-height: 1rem;
                            color: ${isSwitchedOn ? '#212121' : '#979797'};
                            text-align: center;
                            padding: ${isDesktopView ? '8px 18px' : '8px 16px'};
                            pointer-events: none;
                            justify-content: center;
                            border-radius: 8px;
                            opacity: ${isVisible ? '1' : '0'};
                            border: 1.5px solid ${isSwitchedOn ? '#ffd44d' : 'transparent'};
                        }
                    }
                `}
            </style>
            <style jsx global>
                {`
                    .CustomSwitchOn {
                        /* Use in locale translation */
                        &__saveMore {
                            font-size: 10px;
                            line-height: 1rem;
                            font-weight: 600;
                            color: #e91d35;
                            pointer-events: none;
                            white-space: nowrap;
                        }
                    }
                `}
            </style>
        </span>
    );
};

CustomSwitchOn.propTypes = {
    switchId: PropTypes.string.isRequired,
    onText: PropTypes.node.isRequired,
    isSwitchedOn: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    isDesktopView: PropTypes.bool.isRequired,
    fullWidth: PropTypes.bool.isRequired,
};

export default memo(CustomSwitchOn);
