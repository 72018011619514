import intl from 'react-intl-universal';
import { PRODUCTS_IMAGES as images } from 'config/images/products';
import { CREDITS_ONLY_PAGE_ORIGIN as creditsPO } from 'config/products';

const getCreditPackItems = (std, pageOrigin) => {
    if (!std) {
        return {
            title: intl.get('PRODUCTS.CreditPackYouCanBuy'),
            items: [
                {
                    id: 'image-thumbnail-10',
                    thumbnailWebp: images.imageExtThumbnailWebp,
                    thumbnailPng: images.imageExtThumbnail,
                    icon: images.imageIcon,
                    iconText: intl.get('PRODUCTS.Image'),
                    credits: `${'60'} ${intl.get('SHARED.Credits')}`,
                },
                {
                    id: 'video-thumbnail-30',
                    thumbnailWebp: images.videoExtThumbnailWebp,
                    thumbnailPng: images.videoExtThumbnail,
                    icon: images.videoIcon,
                    iconText: intl.get('PRODUCTS.Video'),
                    credits: `${'80'} ${intl.get('SHARED.Credits')}`,
                },
                {
                    id: 'audio-thumbnail-15',
                    thumbnailWebp: images.audioThumbnailExtWebp,
                    thumbnailPng: images.audioThumbnailExt,
                    icon: images.audioIcon,
                    iconText: intl.get('PRODUCTS.Audio'),
                    credits: `${'60'} ${intl.get('SHARED.Credits')}`,
                },
            ],
            // footerText: intl.get('PRODUCTS.CreditsAreValidForUseAnytime'),
        };
    }

    return {
        title: intl.get('PRODUCTS.CreditPackYouCanBuy'),
        items: [
            {
                id: 'image-thumbnail-10',
                thumbnailWebp: images.imageThumbnailWebp,
                thumbnailPng: images.imageThumbnail,
                icon: images.imageIcon,
                iconText: intl.get('PRODUCTS.Image'),
                credits: `${'10'} ${intl.get('SHARED.Credits')}`,
            },
            ...(creditsPO.includes(pageOrigin)
                ? []
                : [
                      {
                          id: 'ai-image-thumbnail-10',
                          thumbnailWebp: images.aiImageThumbnailWebp,
                          thumbnailPng: images.aiImageThumbnail,
                          icon: images.aiImageIcon,
                          iconText: intl.get('PRODUCTS.AiImage'),
                          credits: `${'10'} ${intl.get('SHARED.Credits')}`,
                      },
                  ]),
            {
                id: 'video-thumbnail-30',
                thumbnailWebp: images.videoThumbnailWebp,
                thumbnailPng: images.videoThumbnail,
                icon: images.videoIcon,
                iconText: intl.get('PRODUCTS.Video'),
                credits: `${'30'} ${intl.get('SHARED.Credits')}`,
            },
            {
                id: 'audio-thumbnail-15',
                thumbnailWebp: images.audioThumbnailWebp,
                thumbnailPng: images.audioThumbnail,
                icon: images.audioIcon,
                iconText: intl.get('PRODUCTS.Audio'),
                credits: `${'10'} ${intl.get('SHARED.Credits')}`,
            },
            {
                id: 'font-thumbnail-10',
                thumbnailWebp: images.fontThumbnailWebp,
                thumbnailPng: images.fontThumbnail,
                icon: images.fontIcon,
                iconText: intl.get('PRODUCTS.Font'),
                credits: `${'10'} ${intl.get('SHARED.Credits')}`,
            },
        ],
        // footerText: intl.get('PRODUCTS.CreditsAreValidForUseAnytime'),
    };
};

export default getCreditPackItems;
