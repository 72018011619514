import React, { memo, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Pre-defined Components
import CustomSwitch from 'components/Shared/CustomSwitch';
import CustomTabSwitch from 'components/Shared/CustomTabSwitch';
import CreditPacksItem from './CreditPacksItem';
import getCreditPackItems from './getCreditPackItems';

function CreditPacksCanBuy(props) {
    const {
        locale,
        isDesktopView,
        isTabletView,
        isTabletLGView,
        isMobileView,
        winWidth,
        pageOrigin,
        refCreditPacksCanBuy,
    } = props;

    // Local states
    const [standardLicense, setStandardLicense] = useState(true);

    const creditPackItems = getCreditPackItems(standardLicense, pageOrigin);

    const toggleLicense = () => setStandardLicense((prev) => !prev);

    const CustomSwitchMemo = useMemo(
        () => (
            <CustomSwitch
                on={standardLicense}
                switchId="credit-pack"
                onText={intl.get('PRODUCTS.ComparisonTable__StandardLicense')}
                offText={intl.get('PRODUCTS.ExtendedLicense')}
                onSwitchChange={toggleLicense}
                origin="cpack-can-buy"
                isDesktopView={isDesktopView}
                isTabletView={isTabletView}
                winWidth={winWidth}
            />
        ),
        [standardLicense, isDesktopView, isTabletView, winWidth],
    );

    const CustomTabSwitchMemo = useMemo(
        () => (
            <CustomTabSwitch
                on={standardLicense}
                onText={intl.get('PRODUCTS.ComparisonTable__StandardLicense')}
                offText={intl.get('PRODUCTS.ExtendedLicense')}
                onSwitchChange={toggleLicense}
            />
        ),
        [standardLicense],
    );

    return (
        <section className="CreditPacksCanBuy__mainWrapper" ref={refCreditPacksCanBuy}>
            <div className="CreditPacksCanBuy__wrapper">
                <h2 className="CreditPacksCanBuy__title">{creditPackItems.title}</h2>
                {isMobileView && (
                    <div className="CreditPacksCanBuy__tabSwitchWrapper">{CustomTabSwitchMemo}</div>
                )}
                {(isDesktopView || isTabletView) && CustomSwitchMemo}
                <div className="CreditPacksCanBuy__subTitleWrapper">
                    {standardLicense ? (
                        <div className="CreditPacksCanBuy__subTitle">
                            {intl.get('PRODUCTS.StandardDetails')}
                        </div>
                    ) : (
                        <div className="CreditPacksCanBuy__subTitle">
                            {intl.get('PRODUCTS.ExtendedDetails')}
                        </div>
                    )}
                </div>
                <div className="CreditPacksCanBuy__subWrapper">
                    {creditPackItems.items.map((item) => (
                        <CreditPacksItem key={item.id} item={item} {...props} />
                    ))}
                </div>
                {locale?.country?.code !== 'DE' && (
                    <span className="CreditPacksCanBuy__footerText">
                        {creditPackItems.footerText}
                    </span>
                )}
            </div>

            <style jsx>
                {`
                    .CreditPacksCanBuy {
                        &__mainWrapper {
                            width: 100%;
                            min-height: ${isDesktopView || isTabletLGView
                                ? '542px'
                                : isTabletView
                                ? '427px'
                                : 'auto'};
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            padding: ${isDesktopView || isTabletLGView
                                ? '48px 10px'
                                : isTabletView
                                ? '40px 15px'
                                : '32px 16px'};
                            gap: 10px;
                            background: #f9f9f9;
                        }

                        &__wrapper {
                            width: 100%;
                            height: auto;
                            max-width: 964px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 0px;
                            gap: ${isDesktopView || isTabletLGView ? '32px' : '24px'};
                        }

                        &__subTitleWrapper {
                            width: ${isDesktopView ? '759px' : isTabletView ? '659px' : '240px'};

                            height: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 0px;
                            gap: ${isDesktopView || isTabletLGView ? '32px' : '24px'};
                        }

                        &__title {
                            width: 100%;
                            max-width: ${isDesktopView || isTabletView ? '100%' : '300px'};
                            font-size: ${isDesktopView || isTabletView ? '25px' : '18px'};
                            font-weight: 700;
                            letter-spacing: 0;
                            line-height: ${isDesktopView || isTabletView ? '2.5rem' : '1.5rem'};
                            color: #212121;
                            text-align: center;
                        }

                        &__subTitle {
                            text-align: center;
                            font-size: 12px;
                            line-height: 16px;
                        }

                        &__subWrapper {
                            width: ${isTabletView ? '708px' : '100%'};
                            max-width: 1000px;
                            min-height: ${isDesktopView || isTabletLGView
                                ? '320px'
                                : isTabletView
                                ? '252px'
                                : '100%'};
                            display: flex;
                            flex-direction: ${isDesktopView || isTabletView ? 'row' : 'column'};
                            align-items: ${isDesktopView || isTabletView ? 'flex-start' : 'center'};
                            justify-content: center;
                            flex-wrap: wrap;
                            gap: ${isDesktopView ? '32px' : isTabletView ? '15.88px' : '32px'};
                        }

                        &__tabSwitchWrapper {
                            width: 100%;
                            max-width: 300px;
                        }

                        &__footerText {
                            max-width: ${isDesktopView || isTabletView ? '100%' : '300px'};
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 1.375rem;
                            text-align: center;
                        }
                    }
                `}
            </style>
        </section>
    );
}

CreditPacksCanBuy.propTypes = {
    locale: PropTypes.objectOf(PropTypes.shape).isRequired,
    isDesktopView: PropTypes.bool.isRequired,
    isTabletView: PropTypes.bool.isRequired,
    isTabletLGView: PropTypes.bool.isRequired,
    isMobileView: PropTypes.bool.isRequired,
    winWidth: PropTypes.number.isRequired,
    pageOrigin: PropTypes.string,
    refCreditPacksCanBuy: PropTypes.objectOf(PropTypes.shape).isRequired,
};

CreditPacksCanBuy.defaultProps = {
    pageOrigin: '',
};

export default memo(CreditPacksCanBuy);
