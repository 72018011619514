import { useState } from 'react';
import PropTypes from 'prop-types';

// Sub Components
import CustomTabSwitchItem from './CustomTabSwitchItem';

const CustomTabSwitch = (props) => {
    const { on, onText, offText, onSwitchChange } = props;

    const tabOptions = [
        {
            id: 'option-on-tab',
            label: onText,
            value: on,
        },
        {
            id: 'option-off-tab',
            label: offText,
            value: !on,
        },
    ];

    // Local states
    const [itemDetails, setItemDetails] = useState(tabOptions);

    const toggleTabOption = (id, value) => {
        if (value || itemDetails.length <= 0) return;

        const newItemDetails = [...itemDetails];
        for (let i = 0; i < newItemDetails.length; i += 1) {
            if (newItemDetails[i].id === id) {
                newItemDetails[i].value = !newItemDetails[i].value;
            } else {
                newItemDetails[i].value = false;
            }
        }
        setItemDetails(newItemDetails);
        onSwitchChange();
    };

    return (
        <div className="CustomTabSwitch__wrapper">
            {itemDetails.map((item) => (
                <CustomTabSwitchItem key={item.id} toggleTabOption={toggleTabOption} {...item} />
            ))}

            <style jsx>
                {`
                    .CustomTabSwitch {
                        &__wrapper {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 8px;
                        }
                    }
                `}
            </style>

            <style jsx global>
                {`
                    // Classes used in language translations
                    .CustomSwitchOn {
                        &__paidMonthly {
                            font-size: 12px;
                            line-height: 0.875rem;
                            font-weight: 600;
                            pointer-events: none;
                        }

                        &__saveMore {
                            font-size: 10px;
                            line-height: 0.875rem;
                            font-weight: 600;
                            color: #e91d35;
                            pointer-events: none;
                            white-space: nowrap;
                        }
                    }
                `}
            </style>
        </div>
    );
};

CustomTabSwitch.propTypes = {
    on: PropTypes.bool.isRequired,
    onText: PropTypes.node.isRequired,
    offText: PropTypes.node.isRequired,
    onSwitchChange: PropTypes.func,
};

CustomTabSwitch.defaultProps = {
    onSwitchChange: () => null,
};

export default CustomTabSwitch;
