import { memo } from 'react';
import PropTypes from 'prop-types';

const CustomSwitchOff = (props) => {
    const {
        switchId,
        offText,
        isSwitchedOn,
        isVisible,
        isDesktopView,
        isTabletView,
        fullWidth,
    } = props;

    return (
        <span id={`${switchId}-off-node`} className="CustomSwitchOff__text">
            {offText}

            <style jsx>
                {`
                    .CustomSwitchOff {
                        &__text {
                            width: ${fullWidth ? '100%' : 'auto'};
                            height: 100%;
                            display: flex;
                            align-items: center;
                            white-space: ${isTabletView ? 'normal' : 'nowrap'};
                            font-weight: 600;
                            font-size: ${isDesktopView ? '14px' : '12px'};
                            line-height: 1.125rem;
                            color: ${!isSwitchedOn ? '#212121' : '#979797'};
                            text-align: center;
                            padding: ${isDesktopView ? '8px 18px' : '8px 16px'};
                            pointer-events: none;
                            justify-content: center;
                            border-radius: 8px;
                            opacity: ${isVisible ? '1' : '0'};
                            border: 1.5px solid ${!isSwitchedOn ? '#ffd44d' : 'transparent'};
                        }
                    }
                `}
            </style>
        </span>
    );
};

CustomSwitchOff.propTypes = {
    switchId: PropTypes.string.isRequired,
    offText: PropTypes.node.isRequired,
    isSwitchedOn: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    isDesktopView: PropTypes.bool.isRequired,
    isTabletView: PropTypes.bool.isRequired,
    fullWidth: PropTypes.bool.isRequired,
};

export default memo(CustomSwitchOff);
