import { Credits as CreditsIcon } from '123rf-ui-core/assets';
import PropTypes from 'prop-types';

const CreditPacksItem = (props) => {
    const { item, isDesktopView, isTabletView, isTabletLGView, isMobileView } = props;

    return (
        <div key={item.id} className="CreditPacksItem__container">
            <div className="CreditPacksItem__imageOverlay">
                <img
                    src={item.icon}
                    className="CreditPacksItem__icon"
                    alt={item.credits}
                    width="30px"
                    height="30px"
                    loading="lazy"
                />
                <span>{item.iconText}</span>
            </div>

            <picture>
                <source srcSet={item.thumbnailWebp} type="image/webp" />
                <img
                    src={item.thumbnailPng}
                    className="CreditPacksItem__image"
                    alt={item.credits}
                    width="300px"
                    height="200px"
                    loading="lazy"
                />
            </picture>
            <div className="CreditPacksItem__textContainer">
                <span className="CreditPacksItem__textLineContainer">
                    <CreditsIcon fontSize="30px" />
                    {item.credits}
                </span>
                <span className="CreditPacksItem__textLineContainer">
                    <span className="CreditPacksItem__premium">PREMIUM</span>
                </span>
            </div>

            <style jsx>
                {`
                    .CreditPacksItem {
                        &__container {
                            position: relative;
                            width: ${isDesktopView || isTabletLGView || isMobileView
                                ? '300px'
                                : isTabletView
                                ? '235.11px'
                                : '288px'};
                            max-width: 300px;
                            height: 100%;
                            max-height: 320px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            background: #ffffff;
                            box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
                            border-radius: 10px;
                        }

                        &__imageOverlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: ${isDesktopView || isTabletLGView || isMobileView
                                ? '200px'
                                : isTabletView
                                ? '158.06px'
                                : 'auto'};
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                            background: linear-gradient(
                                0deg,
                                rgba(0, 0, 0, 0.4),
                                rgba(0, 0, 0, 0.4)
                            );
                            border-radius: 10px 10px 0px 0px;
                            font-weight: 700;
                            font-size: 25px;
                            color: #ffffff;
                            line-height: 2.5rem;
                            z-index: 1;
                        }

                        &__icon {
                            width: 30px;
                            height: 30px;
                        }

                        &__textLineContainer {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 0px;
                            gap: 16px;

                            width: 200px;
                        }

                        &__premium {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            padding: 0px 16px;

                            width: 89px;
                            height: 20px;

                            background: #212121;
                            border-radius: 100px;

                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 20px;

                            display: flex;
                            align-items: center;
                            text-align: center;

                            color: #ffd44d;
                        }

                        &__image {
                            width: ${isDesktopView || isTabletLGView
                                ? '300px'
                                : isTabletView
                                ? '235.11px'
                                : '100%'};
                            height: ${isDesktopView || isTabletLGView
                                ? '200px'
                                : isTabletView
                                ? '158.06px'
                                : 'auto'};
                            background: linear-gradient(
                                0deg,
                                rgba(0, 0, 0, 0.4),
                                rgba(0, 0, 0, 0.4)
                            );
                            border-radius: 10px 10px 0px 0px;
                        }

                        &__textContainer {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-size: ${isDesktopView || isTabletLGView || isMobileView
                                ? '25px'
                                : '19.6748px'};
                            color: #000000;
                            line-height: ${isDesktopView || isTabletLGView || isMobileView
                                ? '2.5rem'
                                : '1.938rem'};
                            gap: 8px;
                            margin: 16px 0px;
                        }
                    }
                `}
            </style>
        </div>
    );
};

CreditPacksItem.propTypes = {
    item: PropTypes.objectOf(PropTypes.shape).isRequired,
    isDesktopView: PropTypes.bool.isRequired,
    isTabletView: PropTypes.bool.isRequired,
    isTabletLGView: PropTypes.bool.isRequired,
    isMobileView: PropTypes.bool.isRequired,
};

export default CreditPacksItem;
