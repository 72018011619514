import PropTypes from 'prop-types';

// Pre-defined Components
import { PRODUCTS_IMAGES as images } from 'config/images/products';

const CustomTabSwitchItem = (props) => {
    const { id, label, value, toggleTabOption } = props;

    return (
        <div
            className="CustomTabSwitchItem__wrapper"
            role="presentation"
            onClick={() => toggleTabOption(id, value)}
            onKeyDown={(e) => e.key === 'Enter' && toggleTabOption(id, value)}
        >
            <span className="CustomTabSwitchItem__item">{label}</span>
            <img
                src={images.tickyellow}
                className="CustomTabSwitchItem__tickIcon"
                height="20px"
                width="20.83px"
                alt={label}
            />

            <style jsx>
                {`
                    .CustomTabSwitchItem {
                        &__wrapper {
                            width: 100%;
                            min-height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 10px;
                            padding: 10px;
                            gap: 10px;
                            background: ${value ? '#FFF6DC' : '#ffffff'};
                            border-radius: 8px;
                            border: ${value ? '1.5px solid #f0ba27' : '1.5px solid #e0e0e0'};
                            -webkit-transition: all 0.3s ease-out;
                            -moz-transition: all 0.3s ease-out;
                            -o-transition: all 0.3s ease-out;
                            transition: all 0.3s ease-out;
                        }

                        &__item {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 1rem;
                            color: ${value ? '#212121' : '#979797'};
                            -webkit-transition: color 0.3s ease-out;
                            -moz-transition: color 0.3s ease-out;
                            -o-transition: color 0.3s ease-out;
                            transition: color 0.3s ease-out;
                        }

                        &__tickIcon {
                            opacity: ${value ? '1' : '0'};
                            height: 20px;
                            width: 20.83px;
                            -webkit-transition: opacity 0.3s ease-out;
                            -moz-transition: opacity 0.3s ease-out;
                            -o-transition: opacity 0.3s ease-out;
                            transition: opacity 0.3s ease-out;
                        }
                    }
                `}
            </style>
        </div>
    );
};

CustomTabSwitchItem.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.shape)]).isRequired,
    value: PropTypes.bool.isRequired,
    toggleTabOption: PropTypes.func.isRequired,
};

export default CustomTabSwitchItem;
